import { Controller } from "stimulus";

export default class extends Controller {
  static values = { targets: Array };

  toggle() {
    this.targetElements().forEach((element) =>
      element.classList.toggle("hidden")
    );
  }

  targetElements() {
    return this.targetsValue.map((selector) =>
      document.querySelector(selector)
    );
  }
}
