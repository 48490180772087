import { Controller } from "stimulus";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  connect() {
    // Listen for the custom upload:complete event
    document.addEventListener("upload:complete", this.reload.bind(this));
    document.addEventListener("upload:failed", this.reload.bind(this));
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    document.removeEventListener("upload:complete", this.reload.bind(this));
    document.removeEventListener("upload:failed", this.reload.bind(this));
  }

  // Reload the table without a full page refresh
  reload(event) {
    const notice = event.detail.notice;
    let url = new URL(window.location.href);

    fetch(url)
      .then((res) => res.text())
      .then((html) => {
        history.pushState(null, "", url.href);
        PageLoader.updateDOM(html, "#attachment-list-container");
        this.showNotice(notice);
      })
      .catch((error) => {
        console.error("Error reloading the page:", error);
      });
  }

  showNotice(notice) {
    if (!notice) {
      return;
    }

    this.removeExistingAlert();
    this.createAlertFromNotice(notice);
  }

  removeExistingAlert() {
    const existingAlert = document.querySelector(".alert");
    if (existingAlert) {
      existingAlert.remove();
    }
  }

  createAlertFromNotice(notice) {
    const alertElement = document.createElement("div");
    alertElement.classList.add("alert", `alert-${notice.alertType}`);
    alertElement.role = "alert";

    const contentDiv = document.createElement("div");
    contentDiv.classList.add("alert-content");

    const ul = document.createElement("ul");
    notice.messages.map((message, index) => {
      const li = document.createElement("li");
      li.key = index;
      li.textContent = message;
      ul.appendChild(li);
    });

    alertElement.appendChild(contentDiv);
    contentDiv.appendChild(ul);

    const pageHeading = document.querySelector(".page-heading");
    pageHeading.parentNode.insertBefore(alertElement, pageHeading.nextSibling);
  }
}
