import React, { useState } from "react";
import { number, string, oneOfType, func, bool } from "prop-types";
import Dropzone from "react-dropzone";

import t from "../lib/translate";
import { classSet } from "../lib/helpers";

const AttachmentDropzone = (props) => {
  const MAX_SIZE = props.maxSize * 1000 * 1000;
  const isStandAlone = props.standAlone || false;

  const [uploadState, setUploadState] = useState({ uploading: false });
  const [isDragOver, setIsDragOver] = useState(false);
  const [notice, setNotice] = useState(null);
  const [counter, setCounter] = useState(0);

  const uploadAttachment = async (file) => {
    const data = new FormData();
    data.append("authenticity_token", window.authToken());
    data.append("file", file);
    data.append("resource_type", props.resourceType);
    data.append("resource_id", props.resourceId);

    const response = await fetch("/attachments", {
      method: "POST",
      body: data,
      credentials: "same-origin",
    });

    if (!response.ok) {
      let errors = [t("Ett fel uppstod vid uppladdningen.")];
      try {
        const body = await response.json();
        errors = body.errors;
      } catch (e) {
        console.error(e);
      }
      return { success: false, errors, file: file.name };
    }
    return { success: true, file: file.name };
  };

  const handleDrop = async (acceptedFiles, rejectedFiles) => {
    setIsDragOver(false);

    if (rejectedFiles.length > 0) {
      const rejectedFileNotice = getRejectedFileNotice(rejectedFiles, MAX_SIZE);

      if (isStandAlone) {
        dispatchStandAloneUploadFailedEvent(rejectedFileNotice);
      }

      setNotice(rejectedFileNotice);
      return;
    }

    setUploadState({
      uploading: true,
      total: acceptedFiles.length,
      completed: 0,
    });
    setNotice(null);

    // Upload files sequentially to reduce load on backend
    let results = [];
    for (const file of acceptedFiles) {
      results.push(await uploadAttachment(file));
      setUploadState((state) =>
        Object.assign({}, state, { completed: state.completed + 1 })
      );
    }
    setUploadState({ uploading: false });

    const successCount = results.filter((item) => item.success).length;
    const failureCount = results.filter((item) => !item.success).length;
    const uploadNotice = getUploadNotice(results);

    setNotice(uploadNotice);

    if (isStandAlone) {
      if (failureCount > 0) {
        dispatchStandAloneUploadFailedEvent(uploadNotice);
      } else {
        setCounter(counter + 1);
        dispatchStandAloneUploadCompleteEvent(uploadNotice);
      }
    } else if (successCount > 0) {
      props.onUploadComplete();
    }
  };

  return (
    <React.Fragment>
      {!isStandAlone && notice && (
        <div className={`alert alert-${notice.alertType}`} role="alert">
          <div className="alert-content">
            <ul>
              {notice.messages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <Dropzone
        maxSize={MAX_SIZE}
        multiple={true}
        onDrop={handleDrop}
        onDragEnter={() => setIsDragOver(true)}
        onDragLeave={() => setIsDragOver(false)}
        disabled={uploadState.uploading}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: classSet("dropzone", {
                dragover: isDragOver,
              }),
              style: { marginBottom: "20px" },
            })}
          >
            <input {...getInputProps()} />
            <p>{getDropzoneText(uploadState, MAX_SIZE)}</p>
            {getAllowedExtensionsText(props.allowedExtensions)}
            <span>
              <i className="fa fa-plus" style={{ fontSize: "24px" }}></i>
            </span>
          </div>
        )}
      </Dropzone>
    </React.Fragment>
  );
};

const getUploadNotice = (results) => {
  const successCount = results.filter((item) => item.success).length;
  if (successCount == results.length) {
    return {
      alertType: "success",
      messages: [t("Uppladdningen är klar!")],
    };
  }

  let messages = [];
  let alertType = "danger";
  if (successCount > 0) {
    alertType = "info";
    messages.push(
      t("{:successful} av {:total} filer laddades upp.", {
        successful: successCount,
        total: results.length,
      })
    );
  }

  messages = messages.concat(
    results
      .filter((item) => !item.success)
      .map((item) => `${item.file}: ${item.errors}`)
  );

  return { alertType, messages };
};

const getRejectedFileNotice = (rejectedFiles, maxSize) => {
  for (const file of rejectedFiles) {
    if (file.size > maxSize) {
      return {
        alertType: "danger",
        messages: [
          `${t("Filer får inte vara större än")} ${bytesToMB(maxSize)} MB`,
        ],
      };
    }
  }

  return {
    alertType: "danger",
    messages: [t("Du försökte ladda upp en ogiltig fil.")],
  };
};

const getDropzoneText = (uploadState, maxSize) => {
  if (!uploadState.uploading) {
    return t("Dra filer hit eller klicka för att ladda upp (max {:max} MB)", {
      max: bytesToMB(maxSize),
    });
  }

  if (uploadState.total == 1) {
    return t("Laddar upp...");
  }

  return t("{:completed} av {:total} filer uppladdade...", uploadState);
};

const bytesToMB = (bytes) => Math.round(bytes / 1000 / 1000);

const getAllowedExtensionsText = (extensions) => {
  if (!extensions) {
    return;
  }

  return (
    <p>
      <small>{`${t("Tillåtna filtyper:")} ${extensions}`}</small>
    </p>
  );
};

const dispatchStandAloneUploadFailedEvent = (rejectedFileNotice) => {
  // Trigger custom event to notify Stimulus controller
  const event = new CustomEvent("upload:failed", {
    bubbles: true,
    detail: {
      notice: rejectedFileNotice,
    },
  });
  document.dispatchEvent(event);
};

const dispatchStandAloneUploadCompleteEvent = (uploadNotice) => {
  const event = new CustomEvent("upload:complete", {
    bubbles: true,
    detail: {
      notice: uploadNotice,
    },
  });
  document.dispatchEvent(event);
};

export default AttachmentDropzone;

AttachmentDropzone.propTypes = {
  maxSize: number.isRequired,
  resourceId: oneOfType([string, number]).isRequired,
  resourceType: string.isRequired,
  onUploadComplete: func,
  allowedExtensions: string,
  standAlone: bool,
};
