import React, { useState } from "react";
import { number, string, oneOfType } from "prop-types";
import AttachmentListContainer from "./AttachmentListContainer";
import AttachmentDropzone from "./AttachmentDropzone";

import t from "../lib/translate";

const AttachmentView = ({
  maxUploadSizeMB,
  resourceId,
  resourceType,
  resourcePath,
  allowedExtensions = "",
  simpleList = false,
  isClone = false,
}) => {
  const [counter, setCounter] = useState(0);
  const [notice, setNotice] = useState(null);
  const showDropzone = !isClone;

  return (
    <>
      {notice && (
        <div className={`alert alert-${notice.alertType}`} role="alert">
          <div className="alert-content">
            <ul>
              {notice.messages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {showDropzone && (
        <AttachmentDropzone
          maxSize={maxUploadSizeMB}
          resourceType={resourceType}
          resourceId={resourceId}
          onUploadComplete={() => setCounter(counter + 1)}
          allowedExtensions={allowedExtensions}
        />
      )}

      <AttachmentListContainer
        simpleList={simpleList}
        key={counter}
        parentId={resourceId}
        resourcePath={resourcePath}
        onDelete={() =>
          setNotice({
            alertType: "success",
            messages: [t("Filen har tagits bort.")],
          })
        }
      />
    </>
  );
};

export default AttachmentView;

AttachmentView.propTypes = {
  maxUploadSizeMB: number.isRequired,
  resourceId: oneOfType([string, number]).isRequired,
  resourceType: string.isRequired,
  resourcePath: string.isRequired,
  allowedExtensions: string,
};
