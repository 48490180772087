import React from "react";
import { Line } from "react-chartjs-2";

import {
  legendSettings,
  eventSettings,
  tooltipColorSettings,
} from "../lib/chartHelpers";
import { currency } from "../lib/helpers";
import t from "../lib/translate";

const DepositionChart = ({ data, height, devicePixelRatio }) => {
  const depositionGraphData = {
    labels: data.years,
    datasets: (data.series || []).map((serie, index) => ({
      label: serie.name,
      borderColor: ["#FF9929", "#2F4050"][index],
      backgroundColor: ["#FF9929", "#2F4050"][index],
      pointRadius: 5,
      pointStyle: ["rect", "triangle"][index],
      borderWidth: 0,
      data: serie.data,
      fill: false,
      lineTension: 0,
    })),
  };

  return (
    <div id="graph-analysis">
      <Line
        data={depositionGraphData}
        options={options(devicePixelRatio)}
        height={height}
      />
    </div>
  );
};

const options = (devicePixelRatio) => {
  return {
    maintainAspectRatio: false,
    devicePixelRatio: devicePixelRatio,
    legend: legendSettings,
    ...eventSettings,
    tooltips: {
      position: "nearest",
      callbacks: {
        title: (items) => `${t("År")} ${items[0].label}`,
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const cost = currency(dataset.data[tooltipItem.index], true);

          return dataset.label ? `${dataset.label}: ${cost}` : cost;
        },
      },
      ...tooltipColorSettings,
    },
    scales: {
      xAxes: [
        {
          stacked: false,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            callback: (value) => currency(value),
            maxTicksLimit: 4,
          },
        },
      ],
    },
  };
};

export default DepositionChart;
