import PieChart from "./PieChart";
import { getChartColorsFromStatuses } from "../lib/chartHelpers";
import { arrayOf, shape, string, number, bool } from "prop-types";

const StatusPieChart = ({
  series,
  height,
  alignLegendItems,
  devicePixelRatio,
  showPercentageInLegend = false,
}) => {
  return PieChart({
    series: series,
    height: height,
    alignLegendItems: alignLegendItems,
    devicePixelRatio: devicePixelRatio,
    colors: getChartColorsFromStatuses(series.map((item) => item[0])),
    showPercentageInLegend: showPercentageInLegend,
  });
};

export default StatusPieChart;

StatusPieChart.prototypes = {
  series: arrayOf(shape([string, number])).isRequired,
  height: number,
  alignLegendTimes: string,
  devicePixelRatio: number,
  showPercentageInLegend: bool,
};
